import { Grid } from '@mui/material'
import { type PageProps } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import * as React from 'react'
import { useSessionStorage } from 'react-use'
import { EngageRings } from '../../components/EngageRings'
import { Layout } from '../../components/Layout'
import { SEO } from '../../components/SEO'
import { useEngageRingParams } from '../../hooks/useEngageRingParams'

export const Head = () => (
    <SEO
        title="安い婚約指輪・激安のエンゲージリングなら低価格で高品質なミスプラチナ"
        description="低価格の婚約指輪はメーカー直販ミスプラチナがおすすめ。プラチナのペアリング一覧。"
        page="engagering"
    />
)

const EngageRingPage: React.FC<PageProps> = ({ location }) => {
    const params = useEngageRingParams(location.search, true)
    const [isFirstVisit, setIsFirstVisit] = useSessionStorage('fv', true)
    React.useEffect(() => {
        return () => setIsFirstVisit(false)
    })
    return (
        <Layout breadcrumbs={[{ name: '安い婚約指輪・エンゲージリング' }]}>
            {isFirstVisit && (
                <Grid container spacing={0} component="aside">
                    <Grid item sm={3} />
                    <Grid item sm={6} xs={12}>
                        <StaticImage
                            src="../../images/engagering/engage.jpg"
                            alt=""
                        />
                    </Grid>
                </Grid>
            )}
            <EngageRings {...params} />
            <Grid container spacing={0} component="aside" sx={{ mt: 2 }}>
                <Grid item sm={3} />
                <Grid item sm={6} xs={12}>
                    <a href="https://missplatinum.jp/mydesign">
                        <StaticImage
                            src="../../images/engagering/order.jpg"
                            alt=""
                        />
                    </a>
                </Grid>
            </Grid>
        </Layout>
    )
}

export default EngageRingPage
